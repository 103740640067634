import { createMuiTheme } from '@material-ui/core/styles';
import { esES } from '@material-ui/core/locale';

export const createTheme = (paletteType: 'light' | 'dark') => {
  return createMuiTheme(
    {
      palette: {
        type: paletteType,
        primary: {
          main: '#23b5d3',
          contrastText: '#FFFFFF',
        },
        secondary: {
          main: '#F44336',
          contrastText: '#FFFFFF',
        },
        info: {
          main: '#006987',
          contrastText: '#FFFFFF',
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
      },
      typography: {
        body1: {
          fontFamily: 'Montserrat, sans-serif',
        },
        body2: {
          fontFamily: 'Montserrat, sans-serif',
        },
        button: {
          fontFamily: 'Montserrat, sans-serif',
          fontWeight: 500,
          letterSpacing: 1,
        },
        overline: {
          fontFamily: 'Montserrat, sans-serif',
        },
        caption: {
          fontFamily: 'Montserrat, sans-serif',
        },
        subtitle1: {
          fontFamily: '"Odibee Sans", sans-serif',
        },
        subtitle2: {
          fontFamily: '"Odibee Sans", sans-serif',
        },
        h1: {
          fontFamily: '"Odibee Sans", sans-serif',
          fontSize: '5rem',
        },
        h2: {
          fontFamily: '"Odibee Sans", sans-serif',
        },
        h3: {
          fontFamily: '"Odibee Sans", sans-serif',
        },
        h4: {
          fontFamily: '"Odibee Sans", sans-serif',
        },
        h5: {
          fontFamily: '"Odibee Sans", sans-serif',
        },
        h6: {
          fontFamily: '"Odibee Sans", sans-serif',
        },
      },
    },
    esES
  );
};
