import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';

import App from './containers/App';
import * as sw from './serviceWorkerRegistration';
import { AuthProvider } from './contexts/auth.context';
import { GlobalProvider } from './contexts/global.context';

import 'react-toastify/dist/ReactToastify.min.css';
import './styles/index.scss';

ReactDOM.render(
  <React.StrictMode>
    <GlobalProvider>
      <AuthProvider>
        <CssBaseline />
        <App />
      </AuthProvider>
    </GlobalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// register service worker for PWA support
sw.register();
