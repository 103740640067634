import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import AuthService from '../services/auth.service';
import { useAuth } from '../contexts/auth.context';
import { emailRegex } from '../utils';
import { useGlobalContext } from '../contexts/global.context';

import bgImage from '../assets/img/football.jpg';
import logo from '../assets/img/icon.png';
import styles from '../styles/pages/Login.module.scss';

const bgImageStyle = {
  backgroundImage: `linear-gradient(
    rgba(0, 0, 0, 0.25),
    rgba(0, 0, 0, 0.25)
  ),
  url(${bgImage})`,
};

const Login = ({ history }: Props) => {
  const { register, handleSubmit, errors } = useForm<Form>();
  const [loading, setLoading] = useState(false);

  const { user, authLoading } = useAuth();
  const { notify } = useGlobalContext();

  useEffect(() => {
    if (user) history.push('/');
  }, [user, history]);

  const onSubmit = async (data: Form) => {
    const auth = new AuthService();

    if (loading || authLoading) return;
    setLoading(true);

    try {
      const res = await auth.login(data);

      const token = await res.user!.getIdTokenResult();
      if (!token.claims.admin) {
        notify(
          'Lo sentimos, parece que no tienes permisos para acceder a esta página.',
          'info'
        );
      }
    } catch (error) {
      if (
        error.code === 'auth/user-not-found' ||
        error.code === 'auth/wrong-password'
      ) {
        notify('El correo o la contraseña proporcionados son inválidos.', 'error');
      } else if (error.code === 'auth/too-many-requests') {
        notify(
          'Has realizado demasiadas solicitudes. Intenta de nuevo más tarde.',
          'error'
        );
      } else {
        notify('Ocurrió un error. Intenta nuevamente.', 'error');
      }
    }

    setLoading(false);
  };

  const inputError = (key: keyof Form) => (errors[key] ? errors[key]?.message : '');

  return (
    <Grid container component='main' className={styles.login}>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        className={styles.image}
        style={bgImageStyle}
      />
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={9}
        square
        style={{ display: 'flex' }}
      >
        <div className={styles.content}>
          <img src={logo} alt='Logo' width='100px' style={{ margin: 10 }} />
          <Typography component='h1' variant='h5'>
            Inicia Sesión
          </Typography>

          <form noValidate onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            <TextField
              variant='outlined'
              disabled={loading || authLoading}
              margin='dense'
              fullWidth
              label='Correo Electrónico'
              name='email'
              autoComplete='email'
              autoFocus
              helperText={inputError('email')}
              error={!!errors.email}
              inputRef={register({
                required: 'Campo requerido',
                pattern: {
                  value: emailRegex,
                  message: 'Correo inválido',
                },
              })}
              inputProps={{ inputMode: 'email' }}
            />
            <TextField
              variant='outlined'
              disabled={loading || authLoading}
              margin='dense'
              fullWidth
              name='password'
              helperText={inputError('password')}
              error={!!errors.password}
              label='Contraseña'
              type='password'
              id='password'
              autoComplete='current-password'
              inputRef={register({
                required: 'Campo requerido',
              })}
            />
            <Button
              fullWidth
              variant='contained'
              color='primary'
              className={styles.submit}
              type='submit'
            >
              Enviar
              {(loading || authLoading) && (
                <CircularProgress
                  style={{ position: 'absolute', right: 15 }}
                  color='inherit'
                  size={20}
                />
              )}
            </Button>
          </form>
          {process.env.REACT_APP_ENVIRONMENT === 'development' && (
            <Button
              onClick={() =>
                onSubmit({ email: 'dev@netadeportiva.com', password: 'asdasd' })
              }
              fullWidth
              variant='contained'
              color='secondary'
            >
              Dev Login
            </Button>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

interface Props extends RouteComponentProps {}

interface Form {
  email: string;
  password: string;
}

export default withRouter(Login);
