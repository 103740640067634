import React, { useEffect, useRef, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import ButtonBase from '@material-ui/core/ButtonBase';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PowerIcon from '@material-ui/icons/PowerSettingsNew';
import PersonIcon from '@material-ui/icons/Person';

import AuthService from '../../../services/auth.service';
import { useAuth } from '../../../contexts/auth.context';

import styles from '../../../styles/components/Appbar.module.scss';

const AvatarMenu = () => {
  const { location } = useHistory();
  const [open, setOpen] = useState(false);
  const prevOpen = useRef(open);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const { user, setUser } = useAuth();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Escape') {
      event.preventDefault();
      setOpen(false);
    }
  };

  const signOut = async () => {
    const _auth = new AuthService();

    setOpen(false);
    await _auth.logout();
    setUser(null);
  };

  useEffect(() => {
    // return focus to the button when we transitioned from !open -> open
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    if (open && location.pathname !== '/login') setOpen(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      <ButtonBase
        onClick={handleToggle}
        ref={anchorRef}
        style={{ borderRadius: '50%' }}
      >
        {!user?.avatar ? <Avatar /> : <Avatar src={user?.avatar.url} />}
      </ButtonBase>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper elevation={3}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} onKeyDown={handleListKeyDown}>
                  <ListItem
                    component={NavLink}
                    to='/profile'
                    className={styles.link}
                    activeClassName={styles['link__active']}
                    exact
                    button
                    color='inherit'
                  >
                    <ListItemIcon>
                      <PersonIcon />
                    </ListItemIcon>
                    <ListItemText primary='Mi Perfil' />
                  </ListItem>
                  <ListItem button color='inherit' onClick={signOut}>
                    <ListItemIcon>
                      <PowerIcon />
                    </ListItemIcon>
                    <ListItemText primary='Cerrar Sesión' />
                  </ListItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default AvatarMenu;
