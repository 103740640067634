import { AxiosError } from 'axios';
import { ErrorResponse, GenderMap } from '../interfaces';
import { toast, Slide, ToastOptions } from 'react-toastify';

/* eslint-disable no-useless-escape */
export const emailRegex = new RegExp(
  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
);

export const getApiError = (
  err: AxiosError<ErrorResponse>,
  unkownMessage: string = 'Ocurrió un error. Intente nuevamente.'
): string => {
  let error: string = unkownMessage;
  if (err.response?.data?.data[0]?.field === 'slug') {
    error = 'El slug debe ser único.';
    return error;
  }

  if (err.response?.data?.data[0]?.message) {
    error = err.response?.data.data[0].message;
    return error;
  }

  if (err.response?.data?.error) {
    error = err.response.data.error;
    return error;
  }

  return error;
};

export const notify = (
  msg: string,
  variant: 'success' | 'error' | 'info',
  toastId: string = 'toast'
) => {
  const toastOpts: ToastOptions = {
    position: 'bottom-center',
    autoClose: 3500,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    transition: Slide,
    toastId,
  };

  switch (variant) {
    case 'error':
      toast.error(msg, toastOpts);
      break;
    case 'info':
      toast.info(msg, toastOpts);
      break;
    default:
      toast.success(msg, toastOpts);
      break;
  }
};

export const genderMap: GenderMap = {
  'N/A': 0,
  Masculino: 1,
  Femenino: 2,
};
