import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';

import Routes from './Routes';
import { createTheme } from '../utils/theme';
import { useGlobalContext } from '../contexts/global.context';

function App() {
  const { darkTheme } = useGlobalContext();
  const theme = createTheme(darkTheme ? 'dark' : 'light');

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
