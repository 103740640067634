import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useAuth } from '../contexts/auth.context';

interface Props extends RouteProps {
  component: React.FC;
}

const AuthRoute: React.FC<Props> = ({ component: Component, ...rest }) => {
  const { user } = useAuth();

  const renderFunction = (props: any) => {
    if (user) return <Component {...props} />;
    else return <Redirect to={{ pathname: '/login' }} />;
  };

  return <Route {...rest} render={renderFunction} />;
};

export default AuthRoute;
