import React from 'react';
import UsersIcon from '@material-ui/icons/Group';
import PostIcon from '@material-ui/icons/PostAdd';

import MenuItem from './MenuItem';

const items = (
  <>
    <MenuItem path='/users' text='Usuarios' icon={<UsersIcon />} />
    <MenuItem path='/posts' text='Posts' icon={<PostIcon />} />
  </>
);

export default items;
