import React, {
  useState,
  useContext,
  useMemo,
  createContext,
  Dispatch,
  SetStateAction,
  useRef,
} from 'react';
import {
  CompositeDecorator,
  EditorState,
  Editor,
  RawDraftContentState,
} from 'draft-js';

import Link, { findLinkEntities } from '../components/Posts/Editor/Link';
import { Category, UploadedMedia } from '../interfaces';

export const decorator = new CompositeDecorator([
  {
    strategy: findLinkEntities,
    component: Link,
  },
]);

const initialContext: EditorContext = {
  editorState: EditorState.createEmpty(decorator),
  setEditorState: () => {},
  readOnly: false,
  setReadOnly: () => {},
  resetEditorState: () => {},
  setPreviewPost: () => {},
  editorRef: null as any, // Dont know how to init a refObject
  previewPost: null,
};

const EditorContext = createContext(initialContext);

export const EditorProvider: React.FC = ({ children }) => {
  const editorRef = useRef<Editor>(null);
  const [editorState, setEditorState] = useState(EditorState.createEmpty(decorator));
  const [readOnly, setReadOnly] = useState(false);
  const [previewPost, setPreviewPost] = useState<PreviewPost | null>(null);

  const providerValue = useMemo(
    () => ({
      editorState,
      setEditorState,
      readOnly,
      setReadOnly,
      editorRef,
      resetEditorState: () => setEditorState(EditorState.createEmpty(decorator)),
      previewPost,
      setPreviewPost,
    }),
    [editorState, readOnly, previewPost]
  );

  return (
    <EditorContext.Provider value={providerValue}>{children}</EditorContext.Provider>
  );
};

export const useEditor = () => useContext(EditorContext);

// eslint-disable-next-line @typescript-eslint/no-redeclare
interface EditorContext {
  editorState: EditorState;
  setEditorState: Dispatch<SetStateAction<EditorState>>;
  resetEditorState: () => void;
  readOnly: boolean;
  setReadOnly: Dispatch<SetStateAction<boolean>>;
  previewPost: PreviewPost | null;
  setPreviewPost: Dispatch<SetStateAction<PreviewPost | null>>;
  editorRef: React.RefObject<Editor>;
}

export interface PreviewPost {
  title: string;
  content: RawDraftContentState;
  coverImage: UploadedMedia;
  categories: Category[];
  description: string;
}
