import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { useTheme } from '@material-ui/core';

import AvatarMenu from './AvatarMenu';
import { useGlobalContext } from '../../../contexts/global.context';

import logo from '../../../assets/img/logo.png';
import styles from '../../../styles/components/Appbar.module.scss';

const Appbar = () => {
  const { drawerOpen, toggleDrawer } = useGlobalContext();
  const theme = useTheme();

  const _toggleDrawer = () => {
    if (drawerOpen) {
      localStorage.removeItem('drawerOpen');
    } else {
      localStorage.setItem('drawerOpen', JSON.stringify(!drawerOpen));
    }

    toggleDrawer(!drawerOpen);
  };

  return (
    <AppBar
      position='absolute'
      style={{ zIndex: theme.zIndex.drawer + 1 }}
      className={styles.appbar}
      elevation={0}
      color='inherit'
    >
      <Toolbar variant='dense' style={{ padding: `0 ${theme.spacing(2)}px` }}>
        <IconButton
          edge='start'
          color='inherit'
          aria-label='abrir menú'
          onClick={_toggleDrawer}
          size='small'
        >
          {drawerOpen ? (
            <CloseIcon color='primary' fontSize='large' />
          ) : (
            <MenuIcon color='primary' fontSize='large' />
          )}
        </IconButton>
        <Typography
          component='div'
          variant='h5'
          color='primary'
          style={{
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'center',
            alignItems: 'flex-end',
          }}
        >
          <img
            style={{
              maxWidth: 65,
              padding: `0 ${theme.spacing(0.5)}px`,
              justifySelf: 'center',
            }}
            src={logo}
            alt='Logo'
          />
          Admin
        </Typography>
        <AvatarMenu />
      </Toolbar>
    </AppBar>
  );
};

export default Appbar;
