import React from 'react';
import { NavLink } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useGlobalContext } from '../../../contexts/global.context';

import styles from '../../../styles/components/Drawer.module.scss';

const MenuItem = ({ icon, text, path }: Props) => {
  const theme = useTheme();
  const { drawerOpen } = useGlobalContext();
  const tooltipClasses = useTooltipStyles();

  const paddingY = theme.spacing(1);
  const paddingX = theme.spacing(2.5);

  return (
    <Tooltip
      classes={tooltipClasses}
      title={text}
      placement='right'
      disableHoverListener={drawerOpen}
      arrow
    >
      <ListItem
        style={{ padding: `${paddingY}px ${paddingX}px` }}
        component={NavLink}
        to={path}
        className={styles.link}
        activeClassName={styles['link__active']}
        button
        color='inherit'
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={text} />
      </ListItem>
    </Tooltip>
  );
};

const useTooltipStyles = makeStyles((theme) => {
  return {
    tooltip: {
      backgroundColor: theme.palette.primary.dark,
      fontSize: 16,
    },
    arrow: {
      color: theme.palette.primary.dark,
    },
  };
});

interface Props {
  text: string;
  icon: JSX.Element;
  path: string;
}

export default MenuItem;
