import React, {
  useEffect,
  useState,
  useMemo,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
} from 'react';
import { CurrentUser } from '../interfaces';
import AuthService from '../services/auth.service';

import firebase from '../utils/firebase';
import { useGlobalContext } from './global.context';

const initialAuthContext: AuthContext = {
  user: null,
  setUser: () => {},
  firebaseUser: null,
  authLoading: false,
};

const AuthContext = createContext(initialAuthContext);

export const AuthProvider: React.FC = ({ children }) => {
  const { notify } = useGlobalContext();
  const [authLoading, setAuthLoading] = useState(false);
  const [user, setUser] = useState<CurrentUser | null>(null);
  const [firebaseUser, setFirebaseUser] = useState<firebase.User | null>(null);

  const providerValue = useMemo(
    () => ({ user, setUser, firebaseUser, authLoading }),
    [user, setUser, firebaseUser, authLoading]
  );

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (fbUser) => {
      setAuthLoading(true);
      if (fbUser) {
        const token = await fbUser.getIdTokenResult();

        if (!!token.claims.admin) {
          setFirebaseUser(fbUser);
          const auth = new AuthService();

          try {
            const idToken = await fbUser.getIdToken();
            const {
              data: { data: user },
            } = await auth.currentUser(idToken);

            if (!fbUser.emailVerified) {
              notify('Recuerda verificar tu correo.', 'info', 'verifyEmailToast');
            }

            setUser(user);
          } catch (authError) {
            await firebase.auth().signOut();
          }
        } else {
          // Only allow user to sign in if admin claim is present
          await firebase.auth().signOut();
        }

        setAuthLoading(false);
      } else {
        setAuthLoading(false);
        setFirebaseUser(null);
        setUser(null);
      }
    });

    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <AuthContext.Provider value={providerValue} children={children} />;
};

export const useAuth = () => useContext(AuthContext);

// eslint-disable-next-line @typescript-eslint/no-redeclare
interface AuthContext {
  user: CurrentUser | null;
  firebaseUser: firebase.User | null;
  setUser: Dispatch<SetStateAction<CurrentUser | null>>;
  authLoading: boolean;
}
