import React, {
  useContext,
  useMemo,
  useState,
  createContext,
  useEffect,
} from 'react';
import { ToastContainer } from 'react-toastify';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';

import { notify } from '../utils';

const initialContext: IGlobalContext = {
  drawerOpen: true,
  darkTheme: true,
  setDarkTheme: () => {},
  toggleDrawer: () => {},
  notify: () => {},
};

const GlobalContext = createContext(initialContext);

const prefersDark = () => {
  if (
    !localStorage.getItem('darkTheme') &&
    !(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)
  ) {
    return false;
  }

  if (localStorage.getItem('darkTheme') === 'false') return false;

  return true;
};

export const GlobalProvider: React.FC = ({ children }) => {
  const isDrawerOpened = localStorage.getItem('drawerOpen');
  const [drawerOpen, toggleDrawer] = useState(isDrawerOpened ? true : false);
  const [darkTheme, setDarkTheme] = useState(prefersDark());

  const providerValue = useMemo(
    () => ({ drawerOpen, darkTheme, setDarkTheme, toggleDrawer, notify }),
    [drawerOpen, darkTheme]
  );

  useEffect(() => {
    if (!darkTheme) {
      localStorage.setItem('darkTheme', 'false');
    } else {
      localStorage.setItem('darkTheme', 'true');
    }
  }, [darkTheme]);

  return (
    <GlobalContext.Provider value={providerValue}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
        {children}
        <ToastContainer
          position='bottom-center'
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </MuiPickersUtilsProvider>
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => useContext(GlobalContext);

interface IGlobalContext {
  drawerOpen: boolean;
  darkTheme: boolean;
  setDarkTheme: React.Dispatch<React.SetStateAction<boolean>>;
  toggleDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  notify: (
    msg: string,
    variant: 'success' | 'error' | 'info',
    toastId?: string
  ) => void;
}
