import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import AuthRoute from './AuthRoute';
import Dashboard from '../components/layout/Dashboard';
import LoginPage from '../pages/Login.page';

import Spinner from '../components/ui/Spinner';

const Routes = () => {
  return (
    <Suspense fallback={Spinner({ overlay: false })}>
      <Switch>
        <Route path='/login' exact component={LoginPage} />
        <AuthRoute path='/' component={Dashboard} />
      </Switch>
    </Suspense>
  );
};

export default Routes;
