import axios, { AxiosInstance } from 'axios';
import { CurrentUser, User, SuccessResponse } from '../interfaces';
import firebase from '../utils/firebase';

export default class AuthService {
  private auth: firebase.auth.Auth;
  private api: AxiosInstance;

  constructor() {
    this.auth = firebase.auth();
    this.api = axios.create({
      baseURL: `${process.env.REACT_APP_API_BASE_URL}/auth`,
    });
  }

  async login({ email, password }: { email: string; password: string }) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  async logout() {
    await this.auth.signOut();
  }

  async registerUser(data: CreateUserRequest) {
    return this.api.post<SuccessResponse<User>>('/register', data);
  }

  async currentUser(idToken: string) {
    return this.api.get<SuccessResponse<CurrentUser>>('/current-user', {
      headers: { Authorization: `Bearer ${idToken}` },
    });
  }
}

interface CreateUserRequest {
  email: string;
  password: string;
  name: string;
  surnames: string;
  gender: number;
  dateOfBirth: Date;
}
