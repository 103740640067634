import React, { lazy, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Spinner from '../components/ui/Spinner';
import { EditorProvider } from '../contexts/editor.context';

const PostsPage = lazy(() => import('../pages/post/index.page'));
const CreatePostPage = lazy(() => import('../pages/post/CreatePost.page'));
const UpdatePostPage = lazy(() => import('../pages/post/UpdatePost.page'));
const PostPreviewPage = lazy(() => import('../pages/post/PostPreview.page'));
const UsersPage = lazy(() => import('../pages/Users.page'));
const ProfilePage = lazy(() => import('../pages/Profile.page'));
const UserProfilePage = lazy(() => import('../pages/UserProfile.page'));
const NotFound = lazy(() => import('../pages/NotFound.page'));

const DashboardRoutes = () => {
  return (
    <Suspense fallback={Spinner({ overlay: false })}>
      <Switch>
        <Route exact path='/not-found' component={NotFound} />
        <Route exact path='/profile' component={ProfilePage} />
        <Route exact path='/profile/:id' component={UserProfilePage} />
        <Route exact path='/users' component={UsersPage} />
        <Route path='/posts' render={({ match: { url } }) => PostRoutes(url)} />
        <Redirect exact path='/' to='profile' />
        <Redirect path='*' to='not-found' />
      </Switch>
    </Suspense>
  );
};

const PostRoutes = (url: string) => {
  return (
    <EditorProvider>
      <Switch>
        <Route exact path={url} component={PostsPage} />
        <Route exact path={`${url}/create`} component={CreatePostPage} />
        <Route exact path={`${url}/preview`} component={PostPreviewPage} />
        <Route exact path={`${url}/update/:slug`} component={UpdatePostPage} />
        <Route exact path={`${url}/preview/:slug`} component={PostPreviewPage} />
        <Redirect path='*' to='/not-found' />
      </Switch>
    </EditorProvider>
  );
};

export default DashboardRoutes;
